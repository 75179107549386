import React, { Component } from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Eyeglasses from '../../images/eyeglasses-workflow-icon.js';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';

import {
  CardIcon,
  CarouselCard,
  InstructionCard,
  InstructionPanel,
  WorkflowCarousel,
} from './workflow-icons.styled';

class WorkflowIcons extends Component {
  cycleCards = () => {
    let cards = document.getElementsByClassName('animated-card');

    if (cards) {
      let cardCount = cards.length;
      let activeCard;

      for (let i = 0; i < cards.length; i++) {
        if (cards[i].classList.contains('active-card')) {
          activeCard = i;
        }
      }

      cards[activeCard].classList.remove('active-card');

      if (activeCard === cardCount - 1) {
        cards[0].classList.add('active-card');
      } else {
        cards[++activeCard].classList.add('active-card');
      }
    }
  };

  expandCarousel() {
    document
      .getElementsByClassName('workflow-carousel')[0]
      .classList.add('expanded');
  }

  componentDidMount() {
    // let interval = setInterval(this.cycleCards, 5000);
  }

  render() {
    return (
      <WorkflowCarousel className='workflow-carousel expanded'>
        {/* <CarouselCard onClick={this.expandCarousel} className="animated-card active-card">
          <CardIcon>
            <FavoriteBorderIcon htmlColor={"white"} />
          </CardIcon>
          <h3>Pick up to 10 frame favorites</h3>
        </CarouselCard>
        <CarouselCard onClick={this.expandCarousel} className="animated-card">
          <CardIcon>
            <Eyeglasses htmlColor={"white"} />
          </CardIcon>
          <h3>Try them on during your exam</h3>
        </CarouselCard>
        <CarouselCard onClick={this.expandCarousel} className="animated-card">
          <CardIcon>
            <LocalMallOutlinedIcon htmlColor={"white"} />
          </CardIcon>
          <h3>Buy in-store or online</h3>
        </CarouselCard> */}
        <InstructionPanel
          className={'instruction-panel'}
          onClick={this.props.handleOpen}
        >
          <InstructionCard>
            <CardIcon className='instruction-icon'>F</CardIcon>
            <p>
              Find frames using our{' '}
              <a onClick={this.props.handleOpen}>Frame Selector</a>
            </p>
          </InstructionCard>
          <InstructionCard>
            <CardIcon className='instruction-icon'>A</CardIcon>
            <p>Add up to 10 frames to your favorites</p>
          </InstructionCard>
          <InstructionCard>
            <CardIcon className='instruction-icon'>S</CardIcon>
            <p>Send your selection to us for free</p>
          </InstructionCard>
          <InstructionCard>
            <CardIcon className='instruction-icon'>T</CardIcon>
            <p>Try on your favorites during your visit</p>
          </InstructionCard>
        </InstructionPanel>
      </WorkflowCarousel>
    );
  }
}

export default WorkflowIcons;
