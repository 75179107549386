import * as React from 'react';
import {
  Hero,
  Seo,
  CatalogCards,
  CatalogFilters,
  FDButton,
  LogoBar,
  ModalOverlay,
} from '../components';
import WorkflowIcons from '../components/workflow-icons/workflow-icons';
import { FlexRow } from '../components/app/app.styled';
import { useCatalog } from '../provider/catalog-context';
import { initGA, Event } from '../utils';
import { graphql, navigate } from 'gatsby';
import { isBrowser } from '../utils';
import ProductSuggestion from '../components/product-suggestions/product-suggestions';
import { usePopularProducts } from '../provider/popular-products-context';
import { environment } from '../environments/environments';
import { useFrameSelector } from '../provider/frame-selector-context';
import { useLensSelector } from '../provider/lens-context';
import { useAbandonedCart } from '../provider/abandon-cart-context';
import { usePastPurchase } from '../provider/past-purchase-context';

export const query = graphql`
  query Index {
    allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [isProduct, setIsProduct] = React.useState(false);

  const { facetFilters } = useCatalog();

  const {
    open,
    setOpen,
    index,
    gender,
    handleSelectAll,
    handleButtonClick,
    handleBack,
    handleOpen,
    handleClose,
    handleShopClick,
  } = useFrameSelector();

  const {
    popularCurrentPage,
    setPopularCurrentPage,
    popularPaginatedArray,
    setPopularProductsPerFrame,
  } = usePopularProducts();
  const title = 'Popular In Your Area';

  const {
    pastPurchaseCurrentPage,
    setPastPurchaseCurrentPage,
    pastPurchasePaginatedArray,
    setPastPurchaseProductsPerFrame,
  } = usePastPurchase();
  const pastPurchaseTitle = 'Recommended for You';

  const logoUrlMap = new Map(
    data.allFile.edges
      .map((item) => {
        let url = item.node.publicURL;
        let urlSplit = item.node.publicURL.split('/');
        let nameSplit = urlSplit[urlSplit.length - 1].split('-');
        if (nameSplit[0] === 'logo') {
          return [urlSplit[urlSplit.length - 1], url];
        }
        return [];
      })
      .filter((entry) => entry[0])
  );

  const colorUrlMap = new Map(
    data.allFile.edges
      .map((item) => {
        let url = item.node.publicURL;
        let urlSplit = item.node.publicURL.split('/');
        let nameSplit = urlSplit[urlSplit.length - 1].split('-');
        if (nameSplit[0] === 'color') {
          return [urlSplit[urlSplit.length - 1], url];
        }
        return [];
      })
      .filter((entry) => entry[0])
  );

  React.useEffect(() => {
    initGA();

    if (isBrowser()) {
      const checkIsProduct =
        window.location.pathname.indexOf('product') >= 0 ? true : false;
      console.log('CHECK IF PRODUCT PAGE', checkIsProduct);
      setIsProduct(checkIsProduct);
    }
  }, []);

  return (
    <>
      <ModalOverlay />
      {!isProduct && (
        <>
          <Seo title='Frame Dream Homepage' />

          <Hero>
            <Hero.Image classes='hero__bg__blue'>
              <Hero.SubHeadline>
                Send frames to us and try on during your exam, at no cost
              </Hero.SubHeadline>
              <WorkflowIcons
                handleOpen={() => {
                  Event('FD_Catalog', 'Click_FAST', ``);
                  handleOpen();
                }}
              />
              <h4 className='shop_by'>Shop by:</h4>
              <Hero.Buttons className='m__b__medium'>
                <FDButton
                  handleClick={(e) => {
                    handleShopClick('Gender', 'Men');
                  }}
                  className={'m__r__medium'}
                >
                  Men
                </FDButton>
                <FDButton
                  handleClick={(e) => {
                    handleShopClick('Gender', 'Women');
                  }}
                  className={'m__r__medium'}
                >
                  Women
                </FDButton>
                <FDButton
                  handleClick={(e) => {
                    handleShopClick('Gender', 'Kids');
                  }}
                  className={'m__r__medium'}
                >
                  Kids
                </FDButton>
              </Hero.Buttons>
              <LogoBar logoUrlMap={logoUrlMap} />
            </Hero.Image>
          </Hero>

          <CatalogFilters />

          {popularPaginatedArray !== undefined &&
            popularPaginatedArray[popularCurrentPage]?.length > 0 && (
              <FlexRow
                className={
                  'm__l__medium m__r__medium layout__center layout__max-width'
                }
                id={'popular-products'}
              >
                <ProductSuggestion
                  title={title}
                  currentPage={popularCurrentPage}
                  setCurrentPage={setPopularCurrentPage}
                  paginatedArray={popularPaginatedArray}
                  setProductsPerFrame={setPopularProductsPerFrame}
                  origin={'catalog'}
                  isProductPage={isProduct}
                  isNavB={false}
                ></ProductSuggestion>
              </FlexRow>
            )}

          {pastPurchasePaginatedArray !== undefined &&
            pastPurchasePaginatedArray[pastPurchaseCurrentPage]?.length > 0 && (
              <FlexRow
                className={
                  'm__l__medium m__r__medium layout__center layout__max-width'
                }
                id={'purchased-products'}
              >
                <ProductSuggestion
                  title={pastPurchaseTitle}
                  currentPage={pastPurchaseCurrentPage}
                  setCurrentPage={setPastPurchaseCurrentPage}
                  paginatedArray={pastPurchasePaginatedArray}
                  setProductsPerFrame={setPastPurchaseProductsPerFrame}
                  origin={'catalog'}
                  isProductPage={isProduct}
                  isNavB={true}
                ></ProductSuggestion>
              </FlexRow>
            )}

          <FlexRow
            id='catalog'
            className={
              'm__l__medium m__r__medium layout__center layout__max-width layout__max-width-inner'
            }
          >
            <CatalogCards
              handleOpen={() => {
                Event('FD_Catalog', 'Click_Frame_Selector', ``);
                handleOpen();
              }}
              colorUrlMap={colorUrlMap}
            />
          </FlexRow>
        </>
      )}
    </>
  );
};

export default IndexPage;
