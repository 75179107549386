import React from 'react';

const EyeglassesIcon = ({ htmlColor, ...restProps }) => {
  return (
    <span {...restProps}>
      <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0, 0, 400,400"><g id="svgg"><path id="path0" d="M80.176 119.183 C 66.331 121.934,53.710 127.901,42.933 136.792 L 39.381 139.723 23.792 139.979 C 9.375 140.215,7.998 140.362,5.469 141.938 C -1.022 145.983,-2.166 154.769,3.068 160.382 L 6.136 163.672 13.615 163.943 C 17.728 164.092,21.094 164.313,21.094 164.436 C 21.094 164.558,20.192 167.054,19.089 169.983 C -1.538 224.785,37.604 282.031,95.703 282.031 C 151.935 282.031,191.701 226.975,173.742 173.985 L 171.942 168.673 176.677 165.612 C 192.030 155.689,209.406 155.946,224.483 166.319 L 227.970 168.718 225.731 175.681 C 208.900 228.018,248.803 282.031,304.297 282.031 C 362.396 282.031,401.538 224.785,380.911 169.983 C 379.808 167.054,378.906 164.558,378.906 164.436 C 378.906 164.313,382.272 164.092,386.385 163.943 L 393.864 163.672 396.932 160.382 C 402.166 154.769,401.022 145.983,394.531 141.938 C 392.002 140.362,390.625 140.215,376.208 139.979 L 360.619 139.723 357.067 136.792 C 324.280 109.743,277.538 111.716,246.513 141.460 L 240.234 147.480 237.500 145.560 C 216.025 130.484,183.705 130.474,162.500 145.537 L 159.766 147.480 153.487 141.460 C 133.651 122.444,106.021 114.047,80.176 119.183 M109.551 143.695 C 150.951 154.283,167.382 204.207,140.244 236.950 C 105.856 278.441,37.988 253.917,37.988 200.000 C 37.988 162.506,73.587 134.497,109.551 143.695 M318.145 143.695 C 359.453 154.260,375.914 204.099,348.909 236.838 C 314.561 278.480,246.581 254.007,246.581 200.000 C 246.581 162.469,282.146 134.488,318.145 143.695 " stroke="none" fill={htmlColor} fill-rule="evenodd"></path></g></svg>
    </span>
  );
};

export default EyeglassesIcon;