import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const WorkflowCarousel = styled.div`
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 50px;
  max-width: 400px;
  position: relative;
  width: calc(100% - (2 * var(--app-gutter)));
  &.expanded{
    .instruction-panel{
      display: flex;
      opacity: 1;
      transition: opacity 1s ease, height 1s ease;
    }
    .animated-card{
      display: none;
    }
  }
  @media screen and (max-width: ${SCREEN_SIZE.sm}){
    margin: 30px 32px;
  }
`;

export const CarouselCard = styled.div`
  background-color: white;
  border: 3px solid var(--app-color-primary);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-wrap: no-wrap;
  opacity: 0;
  position: absolute;
  padding: 10px;
  transition: opacity 1s;
  width: 100%;
  h3{
    font-weight: bold;
  }
  &.active-card{
    animation: slidein 1s;
    opacity: 1;
  }
  @keyframes slidein {
    from {
      left: 100px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
`;

export const CardIcon = styled.div`
  background-color: var(--app-color-primary);
  border-radius: 30px;
  height: 35px;
  margin-right: 10px;
  width: 35px;
  svg{
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    width: 30px;
  }
`;

export const InstructionPanel = styled.div`
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const InstructionCard = styled.div`
  align-items: center;
  background-color: white;
  border: 3px solid var(--app-color-primary);
  border-top: none;
  display: flex;
  flex-wrap: no-wrap;
  opacity: 0.9;
  padding: 5px;
  width: 100%;
  h3{
    font-weight: bold;
  }
  a{
    color: blue;
  }
  .instruction-icon{
    color: white;
    font-size: 20px;
    padding: 5px 12px;
    text-align: center;
  }
  :nth-of-type(1){
    border-top: 3px solid var(--app-color-primary);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :nth-of-type(4){
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    p{
      font-size: 0.8rem;
    }
  }
`;